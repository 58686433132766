import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import isEmpty from "lodash.isempty";
import {Button, Card, Checkbox, Grid, GridColumn, GridRow, Image, Input, Modal, Radio, Select} from "semantic-ui-react";
import SearchAddr from "./SearchAddr";
import SearchAddrMo from "./SearchAddrMo";
import "./OnlineRequest.css"
import PersonalInformModal from "./PersonalInformModal";
import PersonalInformModalMo from "./PersonalInformModalMo";
import OnlineRequestLogo from '../assets/onlineRequestLogo.svg';
import OnlineRequestMoLogo from '../assets/onlineRequestMoLogo.svg';
import crypto from 'crypto';
import {sendNaverWcsLog} from "../utils/windowUtil";
import ClipLoader from "react-spinners/ClipLoader";

const PublicKey = {
    key: require('../key/publickey').default, padding: crypto.constants.RSA_PKCS1_PADDING
};

const encryptByPublicKey = (plaintext) => {
    return crypto.publicEncrypt(PublicKey, Buffer.from(plaintext, 'utf8')).toString('base64');
};

export const RequestForm = () => {


    const submitBtnStyle = {
        marginTop: "50px",
        width: "280px",
        height: "64px",
        border: "1px solid #9E9E9E",
        backgroundColor: "#9E9E9E",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: "700",
        fontSize: "20px",
    }

    const tableThStyle = {
        textAlign: "left", // padding: "10px"
    }

    const textAreaStyle = {
        width: "100%",
        minHeight: '157px',
        resize: "none",
        borderRadius: "8px",
        border: "1px solid #d4d4d5",
        padding: "12px",
        whiteSpace: 'pre-wrap'
    }
    const inputStyle = {
        marginBottom: "24px", height: "48px", width: "100%"
    }
    const inputStyle2 = {
        marginBottom: "24px", height: "48px", width: "100%"
    }
    const gridStyle = {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
    }
    const gridRowStyle = {
        paddingTop: "0px",
        paddingBottom: "0px",
    }


    const [showLoading, setShowLoading] = React.useState(false);

    const [personalCheck, setPersonalCheck] = React.useState(false);
    const [personalButtonDisableState, setPersonalButtonDisableState] = React.useState(true);

    const [ORG_NM, setORG_NM] = React.useState('');
    const [CELL_NO, setCELL_NO] = React.useState('');
    const [EMAIL_ADDR, setEMAIL_ADDR] = React.useState('');
    const [MY_ADDR, setMY_ADDR] = React.useState('');
    const [CONTENT, setCONTENT] = React.useState('');
    const [CONTENT_COUNT, setCONTENT_COUNT] = React.useState(0);
    const [REQUEST_NUM, setREQUEST_NUM] = useState();



    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const [validationCellNoAlert1, setValidationCellNoAlert1] = React.useState(false);
    const [validationCellNoAlert2, setValidationCellNoAlert2] = React.useState(false);
    const [validationEmailAlert, setValidationEmailAlert] = React.useState(false);
    const [validationOrgNmAlert, setValidationOrgNmAlert] = React.useState(false);
    const [validationContentAlert, setValidationContentAlert] = React.useState(false);

    //담당자 연락처 vaildation Check
    const [phoneNumberFlag, setPhoneNumberFlag] = React.useState(false);
    const [emailFlag, setEmailFlag] = React.useState(false);

    React.useEffect(() => {
        commonValidationCheck();
    }, [personalCheck, ORG_NM, phoneNumberFlag, emailFlag, MY_ADDR, REQUEST_NUM]);

    const orgNmRef = useRef(null);
    const emailRef = useRef(null);
    const contentRef = useRef(null);

    const OnlineRequestConfirm = function () {

        const loadingStyle = {
            display: "block", color: "#000000"
        };


        const confirmStyle = {
            textAlign: "center",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "28px",
            letterSpacing: "-0.4px",
            color: "#000000"
        }
        const confirmHeader = {
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "18px",
            lineHeight: "24px",
            color: "#000000"
        }
        const confirmImgDivStyle = {
            display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "24px"
        }
        const confirmButtonDivStyle = {
            marginTop: "24px", textAlign: "right"
        }
        const confirmButtonStyle = {
            width: "86px",
            height: "40px",
            border: "1px solid rgba(83, 93, 145, 0.12)",
            boxSizing: "border-box",
            borderRadius: "6px",
            background: "white",

            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#000000"
        }

        return (<Modal
            style={{width: "656px", height: "387px"}}
            open={confirmOpen}
        >
            <Modal.Content>
                <p style={confirmHeader}>상담접수완료</p>
                <div style={confirmImgDivStyle}>
                    <Image src='./images/icon/icon_confirmCheck.svg'
                           style={{width: "90px", height: "90px"}}
                    />
                </div>
                <div style={confirmStyle}>
                    <p>감사합니다.</p>
                    <p>상담 접수 신청이 완료되었습니다.</p>
                    <p>빠른 시간 안에 연락 드리도록 하겠습니다.</p>
                    <p>(영업일 기준 24시간 이내)</p>
                </div>
                <div style={confirmButtonDivStyle}>
                    <Button style={confirmButtonStyle}
                            onClick={() => {
                                window.location.reload();

                            }}>
                        확인
                    </Button>
                </div>

            </Modal.Content>
        </Modal>);
    }
    const checkHeader = {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        color: "#000000"
    }
    const checkContent = {
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "28px",
        color: "#000000"
    }
    const checkBtnDiv = {
        marginTop: "24px", textAlign: "right"
    }
    const checkBtn = {
        width: "86px", height: "40px",

        fontStyle: "normal", fontWeight: "bold", fontSize: "14px", lineHeight: "18px",

        color: "#000000", background: "#FFFFFF", border: "1px solid rgba(83, 93, 145, 0.12)"
    }
    const ValidationCellNoAlert1 = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            // size={'tiny'}
            open={validationCellNoAlert1}
        >
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>숫자만 입력 가능합니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationCellNoAlert1(false);
                                setCELL_NO('');
                                mobileRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }
    const ValidationCellNoAlert2 = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            open={validationCellNoAlert2}>
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>올바른 휴대폰 양식이 아닙니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationCellNoAlert2(false);
                                setCELL_NO('');
                                mobileRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }

    const ValidationEmailAlert = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            open={validationEmailAlert}>
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>올바른 이메일 형식으로 입력해주세요.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationEmailAlert(false);
                                setEMAIL_ADDR('');
                                emailRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }
    const ValidationOrgNmAlert = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            open={validationOrgNmAlert}>
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>기관명은 30자까지 입력 가능합니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationOrgNmAlert(false);
                                setORG_NM('');
                                orgNmRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }

    const ValidationContentAlert = function () {
        return (<Modal
            style={{width: "381px", height: "171px"}}
            centered={false}
            open={validationContentAlert}>
            <Modal.Content>
                <p style={checkHeader}>입력 정보 확인</p>
                <div style={{marginTop: "28px"}}>
                    <p style={checkContent}>문의내용은 1000자까지 입력 가능합니다.</p>
                </div>
                <div style={checkBtnDiv}>
                    <Button style={checkBtn}
                            onClick={() => {
                                setValidationContentAlert(false);
                                setCONTENT('');
                                contentRef.current.focus();
                            }}>
                        확인
                    </Button>
                </div>
            </Modal.Content>
        </Modal>);
    }

    // 제출버튼 활성화 체크
    const commonValidationCheck = () => {
        if (personalCheck && !isEmpty(ORG_NM) && phoneNumberFlag && emailFlag && !isEmpty(MY_ADDR) && !isEmpty(REQUEST_NUM)) {
            // 제출 버튼 활성화
            document.getElementById("submitButtonId").style.backgroundColor = "#FF5A00";
            document.getElementById("submitButtonId").style.border = "1px solid #FF5A00";
            setPersonalButtonDisableState(false);
        } else {
            document.getElementById("submitButtonId").style.backgroundColor = "#9E9E9E";
            document.getElementById("submitButtonId").style.border = "1px solid #9E9E9E";
            // 제출 버튼 비활성화
            setPersonalButtonDisableState(true);
        }
    }

    const onConfirmClick = async () => {

        window.amplitude.track('[클릭] 상담접수하기');

        try {
            setShowLoading(true);
            const formattedContent = CONTENT.replace(/\n/g, "<br>");

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/mail/send`, {
                type: 'html', //html || text
                receivers: [
                  'leedaeho1188@gsretail.com', 
                  'jeh94@gsretail.com', 
                  "kimyj9884@gsretail.com",
                  "hoseng12@gsretail.com",
                  "yoce2000@gsretail.com",
                  "mealbox25@gsretail.com"
                ],
                title: "밀박스25 온라인 상담 신청 접수",
                content: `
                        <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <meta name="viewport" content="width=device-width, initial-scale=1.0">
                          <title>상담 신청</title>
                          <style>
                            body {
                              font-family: Arial, sans-serif;
                              line-height: 1.6;
                              color: #333;
                            }
                            .email-container {
                              max-width: 600px;
                              margin: 0 auto;
                              border: 1px solid #ddd;
                              padding: 20px;
                            }
                            .header {
                              text-align: center;
                              background-color: #f4f4f4;
                              padding: 10px;
                            }
                            .content {
                              margin-top: 20px;
                            }
                            .footer {
                              text-align: center;
                              font-size: 12px;
                              color: #888;
                              margin-top: 20px;
                            }
                          </style>
                        </head>
                        <body>
                          <div class="email-container">
                            <div class="header">
                              <h2>MEALBOX 25 온라인 상담 신청 정보</h2>
                            </div>
                            <div class="content">
                              <p><strong>기업명:</strong> ${ORG_NM}</p>
                              <p><strong>담당자 연락처:</strong> ${CELL_NO}</p>
                              <p><strong>담당자 이메일:</strong> ${EMAIL_ADDR}</p>
                              <p><strong>희망 배송지:</strong> ${MY_ADDR}</p>
                              <p><strong>신청 인원:</strong> ${REQUEST_NUM}</p>
                              <p><strong>문의 내용:</strong></p>
                              <p>${formattedContent}</p>
                            </div>
                            <div class="footer">
                              본 이메일은 자동으로 발송되었습니다.
                            </div>
                          </div>
                        </body>
                        </html>
                `
            });

            setConfirmOpen(true);
            sendNaverWcsLog();
        } catch (e) {
            alert(JSON.stringify(e.response.data.data.errors));
        } finally {
            setShowLoading(false);
        }
    };


    const mobileRef = useRef(null);
    const regex = /^01([0|1|6|7|8|9]) ?([0-9]{3,4}) ?([0-9]{4})$/;
    const numberHandleBlur = (e) => {
        setPhoneNumberFlag(false);
        if (CELL_NO !== '') {
            if (!Number.isInteger(parseInt(CELL_NO.replace('0', '')))) {
                setValidationCellNoAlert1(true);
                return false;
            }

            if (!regex.test(CELL_NO.replace(/\s/g, ''))) {
                setValidationCellNoAlert2(true);
            } else {
                const regexPre = /^01([1|6|7|8|9])$/;
                if (!((CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 11) || (regexPre.test(CELL_NO.substring(0, 3)) && CELL_NO.replace(/\s/g, '').length === 10))) {
                    setValidationCellNoAlert2(true);
                } else {
                    setPhoneNumberFlag(true);
                }
            }
        }
    }

    // 휴대폰 번호 공백 효과
    React.useEffect(() => {
        if (regex.test(CELL_NO.replace(/\s/g, ''))) {
            if (CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 11) {
                setCELL_NO(CELL_NO.replace(/\s/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3'));
            } else if (!CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 10) {
                setCELL_NO(CELL_NO.replace(/\s/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3'));
            }
        }
    }, [CELL_NO]);


    // Email validation
    const emailHandleBlur = () => {
        const regex = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        setEmailFlag(false);
        if (EMAIL_ADDR !== '') {
            if (!regex.test(EMAIL_ADDR)) {
                setValidationEmailAlert(true);
            } else {
                setEmailFlag(true);
            }
        }
    }
    // 기관명 validation
    const orgNameOnChange = (e) => {
        if (e.target.value.length <= 30) {
            setORG_NM(e.target.value);
        } else {
            setValidationOrgNmAlert(true);
        }
    }

    // 글자수 방법 2
    const handleOnChange = (e, name, maxLength, cb) => {
        if (e.target.value.length <= maxLength) {
            cb(e.target.value);
        } else {
            alert(`${name}은 ${maxLength}자까지 입력 가능합니다.`);
        }
    }
    //신청 인원 onChange 함수
    const handleOnChangeRequestNum = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, "");
        if(value !==""){
            value = String(Number(value));
        }


        if (value.length > 10) {
            alert("신청 인원은 10자리까지 입력 가능합니다.");
        } else if (value === '0') {
            alert("0보다 큰 값만 입력 가능합니다.");
            setREQUEST_NUM('');
        } else {
            setREQUEST_NUM(value);
        }
    }

    const contentHandleOnChange = (e) => {
        if (e.target.value.length <= 1000) {
            setCONTENT_COUNT(e.target.value.length);
            setCONTENT(e.target.value);
        } else {
            setValidationContentAlert(true);
        }
    }

    // 제출버튼 활성화를 위한 체크
    React.useEffect(() => {
        commonValidationCheck();
    }, [ORG_NM]);
    React.useEffect(() => {
        commonValidationCheck();
    }, [phoneNumberFlag]);
    React.useEffect(() => {
        commonValidationCheck();
    }, [emailFlag]);

    return (<div id="request" className="onlineRequest">
        <ValidationCellNoAlert1/>
        <ValidationCellNoAlert2/>
        <ValidationEmailAlert/>
        <ValidationOrgNmAlert/>
        <ValidationContentAlert/>

        <OnlineRequestConfirm/>
        <div className="titleDiv">
            <Image src='/images/img_online_request_logo.svg' style={{position: "absolute"}} width="100%"/>
        </div>
        <Grid style={{width: "928px", marginTop: "64px", marginLeft: "260px", marginRight: "260px"}}>
            <Grid.Row style={gridRowStyle}>
                <Grid.Column>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <th style={tableThStyle}>기업명<span style={{color: "red"}}>*</span></th>
                        </tr>
                        <tr>
                            <td>
                                <Input fluid placeholder='기업명 또는 기관명을 입력해 주세요.'
                                       value={ORG_NM}
                                       ref={orgNmRef}
                                       tabIndex={1}
                                       style={inputStyle}
                                       onChange={(e) => orgNameOnChange(e)}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} style={gridRowStyle}>
                <Grid.Column columns={2}>
                    <table style={{width: "100%"}}>
                        <tbody>
                            <tr>
                                <th style={tableThStyle}>담당자 연락처
                                    <span style={{color: "red"}}>*</span>
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <Input type="text" fluid
                                           placeholder="번호만 입력해 주세요."
                                           value={CELL_NO}
                                           ref={mobileRef}
                                           tabIndex={2}
                                           style={inputStyle}
                                           onChange={(e) => setCELL_NO(e.target.value)} onBlur={numberHandleBlur}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid.Column>
                <Grid.Column columns={2}>
                    <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <th style={tableThStyle}>담당자 이메일 주소
                                <span style={{color: "red"}}>*</span>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <Input fluid placeholder='이메일을 입력해 주세요.'
                                       value={EMAIL_ADDR}
                                       ref={emailRef}
                                       tabIndex={3}
                                       style={inputStyle}
                                       onChange={(e) => setEMAIL_ADDR(e.target.value)}
                                       onBlur={emailHandleBlur}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Grid.Column>
            </Grid.Row>
            {/*희망 배송지 (기업주소)*/}
            <Grid.Row style={gridRowStyle}>
                <Grid.Column>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <th style={tableThStyle}>희망 배송지 (기업주소)<span style={{color: "red"}}>*</span></th>
                        </tr>
                        <tr>
                            <td style={{width: "100%"}}>
                                <Input fluid
                                       value={MY_ADDR}
                                       style={inputStyle2}
                                       disabled={true}
                                       onChange={(e) => handleOnChange(e, '배송희망 지역', 100, setMY_ADDR)}
                                />
                            </td>
                            <td>
                                <SearchAddr tabIndex={4} content='주소 찾기' retFunc={setMY_ADDR} isPc={true}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={gridRowStyle}>
                <Grid.Column>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <th style={tableThStyle}>신청 인원<span style={{color: "red"}}>*</span></th>
                        </tr>
                        <tr>
                            <td>
                                <Input fluid
                                       placeholder='희망하는 인원수를 입력해 주세요.'
                                       value={REQUEST_NUM}
                                       tabIndex={5}
                                       style={inputStyle}
                                       onChange={handleOnChangeRequestNum}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{...gridRowStyle}} >
                <Grid.Column style={{width: "100%"}}>
                    <table style={{width: "100%"}}>
                        <tbody>
                        <tr>
                            <th style={tableThStyle}>문의내용</th>
                            <td className="thOption3">{CONTENT_COUNT} / 1000
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                    <textarea placeholder="상세 문의내용을 입력해주세요. &#13;&#10;※ 개인정보 보호를 위해 문의내용 외 개인정보를 입력하지 않도록 유의해 주시기 바랍니다."
                                              style={textAreaStyle}
                                              value={CONTENT}
                                              ref={contentRef}
                                              onChange={(e) => contentHandleOnChange(e)}
                                              tabIndex={6}
                                    >
                                    </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td style={{paddingTop:'12px'}} >
                                <div>
                                    <input type="checkbox"
                                           id="lebelCheck"
                                           checked={personalCheck}
                                           onChange={(e) => {
                                               setPersonalCheck(!personalCheck);
                                           }}
                                    />
                                    <label className="personalCheckLabel" htmlFor="lebelCheck">
                                        (필수) 개인정보 수집·이용 동의
                                    </label>
                                    <PersonalInformModal content='전문보기'/>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <div style={{textAlign: "center", marginBottom: "64px"}}>
            {showLoading ? <ClipLoader
                loading={showLoading}
                cssOverride={{
                    display: "block", margin: "0 auto", borderColor: "grey", backgroundColor: "transparent",
                }}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            /> : <Button type="button"
                         id="submitButtonId"
                         onClick={onConfirmClick}
                         style={submitBtnStyle}
                         data-sc-action='button.click'
                         data-sc-value='상담 접수하기'
                         disabled={personalButtonDisableState}>신청하기
            </Button>}
        </div>
    </div>);
}

export const RequestFormMo = () => {

    const submitBtnStyle = {
        marginTop: "50px",
        width: "312px",
        height: "64px",
        border: "1px solid #9E9E9E",
        backgroundColor: "#9E9E9E",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: "bold",
        fontSize: "16px"
    }

    const textAreaStyle = {
        width: "100%",
        minHeight: "157px",
        resize: "none",
        borderRadius: "8px",
        border: "1px solid #d4d4d5",
        fontFamily: "Noto Sans KR",
        padding: "12px",
        marginBottom: "14px"
    }

    const mobileInputStyle = {
        marginBottom: "24px", height: "48px",
    }
    const mobileInputStyle2 = {
        marginBottom: "24px",
        height: "48px",
        width: "100%",
        backgroundColor: "#ECECEC",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxSizing: "border-box",
        borderRadius: "8px",


    }

    const [showLoading, setShowLoading] = React.useState(false);
    const [personalCheck, setPersonalCheck] = React.useState(false);
    const [personalButtonDisableState, setPersonalButtonDisableState] = React.useState(true);

    const [ORG_NM, setORG_NM] = React.useState('');
    const [CELL_NO, setCELL_NO] = React.useState('');
    const [EMAIL_ADDR, setEMAIL_ADDR] = React.useState('');
    const [MY_ADDR, setMY_ADDR] = React.useState('');
    const [CONTENTMO, setCONTENTMO] = React.useState('');
    const [CONTENT_COUNTMo, setCONTENT_COUNTMo] = React.useState(0);
    const [REQUEST_NUM, setREQUEST_NUM] = useState();


    const [confirmOpenMo, setConfirmOpenMo] = React.useState(false);

    const [phoneNumberFlagMo, setPhoneNumberFlagMo] = React.useState(false);
    const [emailFlagMo, setEmailFlagMo] = React.useState(false);

    // 제출버튼 활성화 체크
    const commonValidationCheckMo = () => {
        if (personalCheck && !isEmpty(ORG_NM) && phoneNumberFlagMo && emailFlagMo && !isEmpty(MY_ADDR) && !isEmpty(REQUEST_NUM)) {
            // 제출 버튼 활성화
            document.getElementById("submitButtonId").style.backgroundColor = "#FF5A00";
            document.getElementById("submitButtonId").style.border = "1px solid #FF5A00";
            setPersonalButtonDisableState(false);
        } else {
            document.getElementById("submitButtonId").style.backgroundColor = "#9E9E9E";
            document.getElementById("submitButtonId").style.border = "1px solid #9E9E9E";
            // 제출 버튼 비활성화
            setPersonalButtonDisableState(true);
        }
    }

    React.useEffect(() => {
        commonValidationCheckMo();
    }, [personalCheck, ORG_NM, phoneNumberFlagMo, emailFlagMo, MY_ADDR]);

    const orgNmRef = useRef(null);
    const emailRef = useRef(null);

    const OnlineRequestConfirmMo = function () {
        const confirmStyleMo = {
            textAlign: "center",
            fontFamily: "Noto Sans KR",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "28px",
            letterSpacing: "-0.4px",
            color: "#000000"
        }
        const confirmHeader = {
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "18px",
            lineHeight: "24px",
            color: "#000000"
        }
        const confirmImgDivStyle = {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "24px",
            marginBottom: "24px",
            textAlign: "center",
        }
        const confirmButtonActionStyle = {
            textAlign: "center", marginTop: "26px", padding: "15px 147px 15px 147px", background: "#FFFFFF"
        }
        const confirmButtonStyle = {
            background: "#FFFFFF",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#000000",
            border: "0px",

            width: "30px",
            height: "18px",
            padding: "0px", // margin: "15px 0 15px 0"
        }

        return (<Modal
            style={{width: "320px", height: "375px"}}
            open={confirmOpenMo}
        >
            <Modal.Content>
                <p style={confirmHeader}>상담접수완료</p>
                <div style={confirmImgDivStyle}>
                    <Image src='./images/icon/icon_confirmCheck.svg'
                           style={{width: "60px", height: "60px"}}
                    />
                </div>
                <div style={confirmStyleMo}>
                    <p>감사합니다.</p>
                    <p>상담 접수 신청이 완료되었습니다.</p>
                    <p>빠른 시간 안에 연락 드리도록 하겠습니다.</p>
                    <p>(영업일 기준 24시간 이내)</p>
                </div>
            </Modal.Content>
            <Modal.Actions style={confirmButtonActionStyle}>
                <button style={confirmButtonStyle}
                        onClick={() => {
                            window.location.reload();
                        }}>
                    확인
                </button>
            </Modal.Actions>

        </Modal>);
    }

    const onConfirmClick = async () => {

        window.amplitude.track('[클릭] 상담접수하기');

        try {
            setShowLoading(true);
            const formattedContent = CONTENTMO.replace(/\n/g, "<br>");

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/mail/send`, {
                type: 'html', //html || text
                receivers: [
                  'leedaeho1188@gsretail.com', 
                  'jeh94@gsretail.com', 
                  "kimyj9884@gsretail.com",
                  "hoseng12@gsretail.com",
                  "yoce2000@gsretail.com",
                  "mealbox25@gsretail.com"
                ],
                title: "밀박스25 온라인 상담 신청 접수",
                content: `
                        <html lang="en">
                        <head>
                          <meta charset="UTF-8">
                          <meta name="viewport" content="width=device-width, initial-scale=1.0">
                          <title>상담 신청</title>
                          <style>
                            body {
                              font-family: Arial, sans-serif;
                              line-height: 1.6;
                              color: #333;
                            }
                            .email-container {
                              max-width: 600px;
                              margin: 0 auto;
                              border: 1px solid #ddd;
                              padding: 20px;
                            }
                            .header {
                              text-align: center;
                              background-color: #f4f4f4;
                              padding: 10px;
                            }
                            .content {
                              margin-top: 20px;
                            }
                            .footer {
                              text-align: center;
                              font-size: 12px;
                              color: #888;
                              margin-top: 20px;
                            }
                          </style>
                        </head>
                        <body>
                          <div class="email-container">
                            <div class="header">
                              <h2>MEALBOX 25 온라인 상담 신청 정보</h2>
                            </div>
                            <div class="content">
                              <p><strong>기업명:</strong> ${ORG_NM}</p>
                              <p><strong>담당자 연락처:</strong> ${CELL_NO}</p>
                              <p><strong>담당자 이메일:</strong> ${EMAIL_ADDR}</p>
                              <p><strong>희망 배송지:</strong> ${MY_ADDR}</p>
                              <p><strong>신청 인원:</strong> ${REQUEST_NUM}</p>
                              <p><strong>문의 내용:</strong></p>
                              <p>${formattedContent}</p>
                            </div>
                            <div class="footer">
                              본 이메일은 자동으로 발송되었습니다.
                            </div>
                          </div>
                        </body>
                        </html>
                `
            });

            setConfirmOpenMo(true);

            sendNaverWcsLog();


        } catch (e) {
            alert(JSON.stringify(e.response.data.data.errors));
        } finally {

            setShowLoading(false);

        }
    };


    const mobileRef = useRef(null);
    const regex = /^01([0|1|6|7|8|9]) ?([0-9]{3,4}) ?([0-9]{4})$/;
    const numberHandleBlur = (e) => {
        setPhoneNumberFlagMo(false);
        if (CELL_NO !== '') {
            if (!Number.isInteger(parseInt(CELL_NO.replace('0', '')))) {
                alert('숫자만 입력 가능합니다.');
                setCELL_NO('');
                mobileRef.current.focus();
                return false;
            }

            if (!regex.test(CELL_NO.replace(/\s/g, ''))) {
                alert('올바른 휴대폰 양식이 아닙니다.');
                setCELL_NO('');
                mobileRef.current.focus();
            } else {
                const regexPre = /^01([1|6|7|8|9])$/;
                if (!((CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 11) || (regexPre.test(CELL_NO.substring(0, 3)) && CELL_NO.replace(/\s/g, '').length === 10))) {
                    alert('올바른 휴대폰 양식이 아닙니다.');
                    setCELL_NO('');
                    mobileRef.current.focus();
                } else {
                    setPhoneNumberFlagMo(true);
                }
            }
        }
    }

// 휴대폰 번호 공백 효과
    React.useEffect(() => {

        if (regex.test(CELL_NO.replace(/\s/g, ''))) {
            if (CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 11) {
                setCELL_NO(CELL_NO.replace(/\s/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3'));
            } else if (!CELL_NO.startsWith('010') && CELL_NO.replace(/\s/g, '').length === 10) {
                setCELL_NO(CELL_NO.replace(/\s/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3'));
            }
        }
    }, [CELL_NO]);


// Email validation
    const emailHandleBlur = () => {
        const regex = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        setEmailFlagMo(false);
        if (EMAIL_ADDR !== '') {
            if (!regex.test(EMAIL_ADDR)) {
                alert('올바른 이메일 형식으로 입력해주세요.');
                setEMAIL_ADDR('');
                emailRef.current.focus();
            } else {
                setEmailFlagMo(true);
            }
        }
    }
// 기관명 validation
    const orgNameOnChange = (e) => {
        if (e.target.value.length <= 30) {
            setORG_NM(e.target.value);
        } else {
            alert(`기관명은 30자까지 입력 가능합니다.`);
        }
    }

    // 제출버튼 활성화를 위한 체크
    React.useEffect(() => {
        commonValidationCheckMo();
    }, [ORG_NM]);
    React.useEffect(() => {
        commonValidationCheckMo();
    }, [phoneNumberFlagMo]);
    React.useEffect(() => {
        commonValidationCheckMo();
    }, [emailFlagMo]);

    // 글자수 방법 2
    const handleOnChange = (e, name, maxLength, cb) => {
        if (e.target.value.length <= maxLength) {
            cb(e.target.value);
        } else {
            alert(`${name}은 ${maxLength}자까지 입력 가능합니다.`);
        }
    }

    //신청 인원 onChange 함수
    const handleOnChangeRequestNum = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, "");
        if(value !==""){
            value = String(Number(value));
        }


        if (value.length > 10) {
            alert("신청 인원은 10자리까지 입력 가능합니다.");
        } else if (value === '0') {
            alert("0보다 큰 값만 입력 가능합니다.");
            setREQUEST_NUM('');
        } else {
            setREQUEST_NUM(value);
        }
    }

    const CONTENT_MOOnChange = (e) => {
        if (e.target.value.length <= 1000) {
            setCONTENT_COUNTMo(e.target.value.length);
            setCONTENTMO(e.target.value);
        } else {
            alert(`문의내용은 1000자까지 입력 가능합니다.`);
        }
    }


    return (
      <div id="request" className="onlineRequestMo">
        <OnlineRequestConfirmMo/>
        <div className="titleDivMo">
            <Image src='/images/img_online_request_logo_mo.svg'/>
        </div>
        <div className="table-div">
            <table style={{width: "100%"}}>
                <tbody>
                <tr>
                    <th className="table-thMo">
                        <label>기업명</label>
                        <label style={{color: "red"}}>*</label>
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} className="thOptionMo">
                        <Input fluid placeholder='기업명 또는 기관명을 입력해주세요.'
                               value={ORG_NM}
                               ref={orgNmRef}
                               style={mobileInputStyle}
                               onChange={(e) => orgNameOnChange(e)}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">
                        <label>담당자 연락처</label>
                        <label style={{color: "red"}}>*</label>
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} className="thOptionMo">
                        <Input type="text" fluid placeholder="번호만 입력해주세요."
                               value={CELL_NO}
                               ref={mobileRef}
                               style={mobileInputStyle}
                               onChange={(e) => setCELL_NO(e.target.value)}
                               onBlur={numberHandleBlur}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">
                        <label>담당자 이메일주소</label>
                        <label style={{color: "red"}}>*</label>
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} className="thOptionMo">
                        <Input fluid placeholder='이메일 주소를 입력해주세요.'
                               value={EMAIL_ADDR}
                               ref={emailRef}
                               style={mobileInputStyle}
                               onChange={(e) => setEMAIL_ADDR(e.target.value)} onBlur={emailHandleBlur}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">
                        <label>희망 배송지 (기업주소)<span style={{color: "red"}}>*</span></label>
                    </th>
                </tr>
                <tr>
                    <td className="thOptionMo2" style={{width: '100%'}}>
                        <Input fluid
                               value={MY_ADDR}
                               style={mobileInputStyle2}
                               disabled={true}
                          // readOnly={true}
                               onChange={(e) => handleOnChange(e, '배송희망 지역', 100, setMY_ADDR)}/>
                    </td>
                    <td style={{paddingRight: '16px'}}>
                        <SearchAddrMo content='주소 찾기' retFunc={setMY_ADDR} isPc={false}/>
                    </td>
                </tr>
                <tr>
                    <th className="table-thMo">
                        <label>인원</label>
                        <label style={{color: "red"}}>*</label>
                    </th>
                </tr>
                <tr>
                    <td colSpan={2} className="thOptionMo">
                        <Input fluid placeholder='희망하는 인원수를 입력해주세요.'
                               value={REQUEST_NUM}
                               style={mobileInputStyle}
                               onChange={handleOnChangeRequestNum}
                        />
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div style={{width: "100%"}}>
            <table style={{width: "100%"}}>
                <tbody>
                <tr>
                    <th className="table-thMo">문의내용</th>
                    <td className="thOptionMo3">{CONTENT_COUNTMo} / 1000</td>
                </tr>
                <tr>
                    <td colSpan="2" className="thOptionMo">
                                <textarea
                                  placeholder='상세 문의내용을 입력해주세요. &#13;&#10;※ 개인정보 보호를 위해 문의내용 외 개인정보를 입력하지 않도록 유의해 주시기 바랍니다.'
                                  style={textAreaStyle}
                                  value={CONTENTMO}
                                  onChange={(e) => CONTENT_MOOnChange(e)}>
                                </textarea>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div className="personalCheckDiv">
            <input type="checkbox"
                //onClick={personalCheckboxClick}
                   id="lebelCheck"
                   checked={personalCheck}
                   onChange={(e) => {
                       if (personalButtonDisableState === true) {
                           document.getElementById("submitButtonId").style.backgroundColor = "#FF5A00";
                           document.getElementById("submitButtonId").style.border = "1px solid #FF5A00";

                       } else {
                           document.getElementById("submitButtonId").style.backgroundColor = "#9E9E9E";
                           document.getElementById("submitButtonId").style.border = "1px solid #9E9E9E";
                       }
                       setPersonalCheck(!personalCheck);
                   }}
            />
            <label className="personalCheckLabel" htmlFor="lebelCheck">(필수) 개인정보 수집·이용 동의</label>
            <PersonalInformModalMo content='전문보기'/>
        </div>
        <div style={{textAlign: "center", marginBottom: "64px"}}>
            {showLoading ? <ClipLoader
                loading={showLoading}
                cssOverride={{
                    display: "block", margin: "0 auto", borderColor: "grey", backgroundColor: "transparent",
                }}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            /> : <Button type="button"
                         id="submitButtonId"
                         onClick={onConfirmClick}
                         style={submitBtnStyle}
                         data-sc-action='button.click'
                         data-sc-value='상담 접수하기'
                         disabled={personalButtonDisableState}>신청하기
            </Button>}
        </div>


    </div>);
}
