import React, {useMemo} from "react";
import {Accordion, Icon, Image} from 'semantic-ui-react'
import {Grid} from 'semantic-ui-react'
import axios from "axios";
import "./Faq.css"
import FaqLogo from "../assets/faqLogo.svg";
import Markdown from "markdown-to-jsx";

export const Faq = () => {
  const [activeIndex, setActiveIndex] = React.useState('');

  const handleClick = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex);
  }

  const faqTitleStyle = {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "28px", /*56px;*/
    // alignItems: "center",
    color: "#000000"
  }
  const faqContentStyle = {
    fontSize: "16px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "normal",
    padding: ".5em 1em 1.5em 1.5em",
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
  }

  const faqList = useMemo(() => {
    return [
      {
        "id": 1,
        "Question": "어떻게 이용하나요?",
        "Answer": "밀박스25 사이트에서 상담 신청 후, 상담사와 구성 및 인원을 확정하여 최종 접수를 합니다."
      },
      {
        "id": 2,
        "Question": "배송은 어떻게 진행되나요?",
        "Answer": "20인 이상 고객사에는 직접 배송해 드리며, 20인 이하 고객사는 신청한 GS25 매장에서 픽업할 수 있습니다."
      },
      {
        "id": 4,
        "Question": "직배송 시간은 어떻게 되나요?",
        "Answer": "요청하신 시간에 맞춰 배송되며, 매장 사정상 시간 준수가 어려울 경우 사전에 배송해 드립니다."
      },
      {
        "id": 5,
        "Question": "직배송 비용은 무료인가요?",
        "Answer": "추가 비용 없이 무료로 배송됩니다. (단, 20식 미만은 점포픽업으로 진행됩니다.)"
      },
      {
        "id": 6,
        "Question": "이용 가능한 지역은 어디인가요?",
        "Answer": "제주를 포함하여 GS25 매장이 있는 전국 어디서나 이용할 수 있습니다."
      },
      {
        "id": 7,
        "Question": "장비 대여는 어떻게 하나요?",
        "Answer": "3개월 이상, 월 구독금액 1백만원 이상으로 정기 구독을 신청하신 고객사에는 이용에 필요한 장비를 무상으로 대여해 드립니다."
      },
      {
        "id": 11,
        "Question": "개인ㆍ단체1회성 고객은 어떻게 주문하면 되나요?",
        "Answer": "개인ㆍ단체1회성 고객은 우리동네GS앱의 '편의점 사전예약'에서 밀박스25를 만나보실 수 있습니다.\n\n▶▶\n[우리동네GS 앱 다운로드](https://abr.ge/j1usds)"
      }
    ]
  }, []);


  return (
    <div className="faq">
      <div className="faqImageDiv">
        <Image src="/images/MEALBOX25_LOGO.svg" style={{width:'242px'}} width="100%"/>
        <div className="faq-title" >자주 묻는 질문</div>
        <img className="image-moo-moo" src="/images/img_moomoo1.png" alt="moo_moo_image" />
      </div>
      <Grid style={{width: "928px", marginLeft: "260px", marginRight: "260px", marginBottom: "64px"}}>
        <Grid.Row>
          <Grid.Column>
            <Accordion styled
                       style={{
                         width: "100%",
                         border: "1px solid #E5E5E5",
                         boxShadow: "none"
                       }}
            >
              {
                faqList.map(({Question, Answer}, index) => {
                  // const iconName = activeIndex === index ? 'dropdown' : 'dropdown'
                  let titleStyle = {...faqTitleStyle};
                  if (index === 0) {
                    titleStyle.borderTop = '0px';
                  }
                  return (
                    <div key={`acc-${index}`}>
                      <Accordion.Title
                        active={activeIndex === index}
                        index={index}
                        onClick={handleClick}
                        style={titleStyle}
                      >
                        <Icon name='dropdown' style={{fontSize: "20px"}}
                              className="Faq-accordion-title-icon"/>
                        {Question}
                      </Accordion.Title>
                      <Accordion.Content
                        style={faqContentStyle}
                        active={activeIndex === index}>
                        <Markdown>{Answer}</Markdown>
                      </Accordion.Content>
                    </div>
                  )
                })
              }
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}
export const FaqMo = () => {
  const [activeIndex, setActiveIndex] = React.useState('');

  const handleClick = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex);
  }

  const faqTitleStyle = {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "28px",
    color: "#000000",
  }
  const faqContentStyle = {
    fontSize: "14px",
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: "normal",
    padding: ".5em 1em 1.5em 1.5em",
    overflowWrap: 'break-word',
  }

  const faqList = useMemo(() => {
    return [
      {
        "id": 1,
        "Question": "어떻게 이용하나요?",
        "Answer": "밀박스25 사이트에서 상담 신청 후, 상담사와 구성 및 인원을 확정하여 최종 접수를 합니다."
      },
      {
        "id": 2,
        "Question": "배송은 어떻게 진행되나요?",
        "Answer": "20인 이상 고객사에는 직접 배송해 드리며, 20인 이하 고객사는 신청한 GS25 매장에서 픽업할 수 있습니다."
      },
      {
        "id": 4,
        "Question": "직배송 시간은 어떻게 되나요?",
        "Answer": "요청하신 시간에 맞춰 배송되며, 매장 사정상 시간 준수가 어려울 경우 사전에 배송해 드립니다."
      },
      {
        "id": 5,
        "Question": "직배송 비용은 무료인가요?",
        "Answer": "추가 비용 없이 무료로 배송됩니다. (단, 20식 미만은 점포픽업으로 진행됩니다.)"
      },
      {
        "id": 6,
        "Question": "이용 가능한 지역은 어디인가요?",
        "Answer": "제주를 포함하여 GS25 매장이 있는 전국 어디서나 이용할 수 있습니다."
      },
      {
        "id": 7,
        "Question": "장비 대여는 어떻게 하나요?",
        "Answer": "3개월 이상, 월 구독금액 1백만원 이상으로 정기 구독을 신청하신 고객사에는 이용에 필요한 장비를 무상으로 대여해 드립니다."
      },
      {
        "id": 11,
        "Question": "개인ㆍ단체1회성 고객은 어떻게 주문하면 되나요?",
        "Answer": "개인ㆍ단체1회성 고객은 우리동네GS앱의 '편의점 사전예약'에서 밀박스25를 만나보실 수 있습니다.\n\n▶▶\n[우리동네GS 앱 다운로드](https://abr.ge/j1usds)"
      }
    ]
  }, []);

  return (
    <div className="faqMo">
      <div className="faqImageDivMo">
        <Image className="faq-title-mo" src="/images/img_faq_title_mo.svg"/>
      </div>
      <Grid style={{width: "100%", marginLeft: "10px", marginRight: "10px", position:'relative'}}>
        <img className="image-moo-moo-mo" src="/images/img_moomoo1.png" alt="image_moo_moo" />
        <Grid.Row>
          <Grid.Column>
            <Accordion styled
                       style={{
                         width: "100%",
                         border: "1px solid #E5E5E5",
                         boxShadow: "none"
                       }}
            >
              {
                faqList.map(({Question, Answer}, index) => {
                  // const iconName = activeIndex === index ? 'dropdown' : 'dropdown'
                  let titleStyle = {...faqTitleStyle};
                  if (index === 0) {
                    titleStyle.borderTop = '0px';
                  }
                  return (
                    <div key={`acc-${index}`}>
                      <Accordion.Title
                        active={activeIndex === index}
                        index={index}
                        onClick={handleClick}
                        style={titleStyle}
                      >
                        <Icon name='dropdown' style={{fontSize: "20px"}}
                              className="Faq-accordion-title-icon"/>
                        {Question}
                      </Accordion.Title>
                      <Accordion.Content
                        style={faqContentStyle}
                        active={activeIndex === index}>
                        <Markdown>{Answer}</Markdown>
                      </Accordion.Content>
                    </div>
                  )
                })
              }
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>

    </div>
  )
}

