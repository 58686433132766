import {Image} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import "./Footer.css";
import FooterPersonalInformModal from "./FooterPersonalInformModal";
import FooterPersonalInformModalMo from "./FooterPersonalInformModalMo";

export const Footer = () => {

    return (
        <div className="footer">
            <div className="frame">
                <div className="logo">
                    <Image src="./images/MEALBOX25_LOGO_BW.svg"/>
                </div>
                <div className="content-wrapper">
                    <div className="content">
                        <div style={{width: "110px"}}>
                            <FooterPersonalInformModal content='개인정보처리방침'/>
                        </div>
                        <label className="item">(주)지에스리테일 대표이사: 허서홍 | 사업자등록번호 : 116-81-18745</label>
                        <label className="item">서울시 강남구 논현로 508 (역삼동 679번지 GS타워)</label>
                        <label className="item">CopyrightⒸ2021 GSRetail. Co. Ltd., All Right Reserved.</label>
                    </div>
                    <div className="vl"></div>
                    <div className='service-customer'>
                        <div className="service">
                            <label className="item" style={{fontWeight: '500'}}>상품 및 서비스 제휴 문의:</label>
                            <a className="itemMail" href="mailto:mealbox25@gsretail.com"> mealbox25@gsretail.com</a>
                        </div>
                        <div className="item" style={{display: 'flex', gap: '2px', marginTop: '26px'}}>
                            <span style={{fontWeight: '500'}}>고객센터</span>
                            <span>:</span>
                            <span>02-2006-3037</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export const FooterMo = () => {

    return (
        <div className="footer-mo">
            <div className="content">
                <div className="logo">
                    <Image src="./images/MEALBOX25_LOGO_BW.svg"/>
                </div>
                <FooterPersonalInformModalMo content='개인정보처리방침'/>
                <label className="item">(주)지에스리테일 대표이사: 허서홍</label>
                <label className="item">사업자등록번호 : 116-81-18745</label>
                <label className="item">서울시 강남구 논현로 508 (역삼동 679번지 GS타워)</label>
                <label className="item">CopyrightⒸ2021 GSRetail. Co. Ltd., All Right Reserved.</label>
            </div>
            <div className="serviceMo">
                <div>
                    <label className="itemServiceMo">제휴 문의 : </label>
                    <a className="itemMailMo" href="mailto:mealbox25@gsretail.com"> mealbox25@gsretail.com</a>
                </div>
                <div style={{marginTop: '12px'}}>
                    <label className="itemServiceMo">고객센터 : </label>
                    <a className="itemPhoneMo" href="tel:0220063037"> 02-2006-3037</a>
                </div>
            </div>
        </div>
      )
};
