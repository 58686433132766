import React, {useEffect} from "react";
import {Card, Dropdown, Header, Image, Modal} from "semantic-ui-react";

const PersonalInformModal = function ({content}) {
  const options = [
    {key: 1, text: '현재 버전', value: 'terms/personal_inform_terms_2025_02.html'},
    {key: 2, text: '이전 버전 (2024년 7월 24일)', value: 'terms/personal_inform_terms_2024_07.html'},
    {key: 3, text: '이전 버전 (2023년 10월 24일)', value: 'terms/personal_inform_terms_2023_09.html'},
    {key: 4, text: '이전 버전 (2023년 5월 22일)', value: 'terms/personal_inform_terms_2023_06.html'},
    {key: 5, text: '이전 버전 (2021년 12월 20일)', value: 'terms/personal_inform_terms_2022_01.html'},
  ];

  const [open, setOpen] = React.useState(false);
  const [htmlFilePath, setHtmlFilePath] = React.useState(options[0].value);
  const [htmlFileString, setHtmlFileString] = React.useState();

  async function fetchHtml() {
    const response = await fetch(htmlFilePath);
    const responseText = await response.text();
    setHtmlFileString(responseText);
  }

  React.useEffect(async () => {
    await fetchHtml();
  }, [htmlFilePath]);

  useEffect(() => {
    const toggleModalDisplay = (displayStyle) => {
      const storeDetailModal = document.getElementById('storeDetailModal');
      if (storeDetailModal) {
        storeDetailModal.style.display = displayStyle;
      }
    };

    const handleElementClick = (event) => {
      const { id } = event.target;
      if (id === 'storeDetailButton') {
        toggleModalDisplay(
          document.getElementById('storeDetailModal').style.display === 'none' ? 'block' : 'none'
        );
      } else if (id === 'storeDetailExitButton' || id === 'storeDetailExitButtonImg') {
        toggleModalDisplay('none');
      }
    };

    // 문서에 이벤트 리스너 추가
    document.addEventListener('click', handleElementClick);

    // 클린업 함수에서 이벤트 리스너 제거
    return () => {
      document.removeEventListener('click', handleElementClick);
    };
  }, [htmlFileString]);



  const modalCloseIconStyle = {
    display: "block",
    marginLeft: "auto",
    width: "12px",
    height: "12px"
  }
  const modalStyle = {
    display: "block",

    fontFamily: "Noto Sans KR",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.7)",

    textDecorationLine: "underline",
    cursor: "pointer",
  }
  const modalTitleStyle = {
    fontFamily: "Noto Sans KR",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.4px",
    color: "#000000"
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      style={{width: "678px", height: "auto"}}
      trigger={<a href="#none" style={modalStyle}>{content}</a>}
    >
      <Modal.Content>
        <Header style={{display: 'flex', alignItems: 'center'}}>
          <span style={modalTitleStyle}>개인정보처리방침</span>
          <div style={{marginLeft: 10}} className='ui form mini'>
            <Dropdown defaultValue={htmlFilePath} options={options} selection onChange={(e, data) => {
              setHtmlFilePath(data.value);
            }}/>
          </div>
          <Image src='./images/icon/icon_close.svg'
                 onClick={() => setOpen(false)}
                 style={modalCloseIconStyle}/>
        </Header>
      </Modal.Content>
      <Modal.Content  scrolling>
        <Modal.Description>
          <Card centered style={{width: "100%", marginBottom: "20px"}}>
            <div dangerouslySetInnerHTML={{__html: htmlFileString}}/>
          </Card>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default PersonalInformModal;
