import React, {useEffect} from "react";
import {Card, Dropdown, Image, Modal} from "semantic-ui-react";

const PersonalInformModal = function ({content}) {
  const options = [
    {key: 1, text: '현재 버전', value: 'terms/personal_inform_terms_2025_02_mobile.html'},
    {key: 2, text: '이전 버전 (2024년 7월 24일)', value: 'terms/personal_inform_terms_2024_07_mobile.html'},
    {key: 3, text: '이전 버전 (2023년 10월 24일)', value: 'terms/personal_inform_terms_2023_09_mobile.html'},
    {key: 4, text: '이전 버전 (2023년 5월 22일)', value: 'terms/personal_inform_terms_2023_06_mobile.html'},
    {key: 5, text: '이전 버전 (2021년 12월 20일)', value: 'terms/personal_inform_terms_2022_01_mobile.html'},
  ];

  const [open, setOpen] = React.useState(false);
  const [htmlFilePath, setHtmlFilePath] = React.useState(options[0].value);
  const [htmlFileString, setHtmlFileString] = React.useState();

  async function fetchHtml() {
    const response = await fetch(htmlFilePath);
    const responseText = await response.text();
    setHtmlFileString(responseText);
  }

  React.useEffect(() => {
    fetchHtml();
  }, [htmlFilePath]);

  const modalLinkStyle = {
    display: "block",
    marginBottom: "6px",
    fontFamily: "Noto Sans KR",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.7)",

    textDecorationLine: "underline",
    cursor: "pointer",
  }
  const modalTitleStyle = {
    fontFamily: "Noto Sans KR",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.4px",
    color: "#1E2530"
  }

  useEffect(() => {
    const toggleModalDisplay = (displayStyle) => {
      const storeDetailModal = document.getElementById('storeDetailModal');
      if (storeDetailModal) {
        storeDetailModal.style.display = displayStyle;
      }
    };

    const handleElementClick = (event) => {
      const { id } = event.target;
      if (id === 'storeDetailButton') {
        toggleModalDisplay(
          document.getElementById('storeDetailModal').style.display === 'none' ? 'block' : 'none'
        );
      } else if (id === 'storeDetailExitButton' || id === 'storeDetailExitButtonImg') {
        toggleModalDisplay('none');
      }
    };

    // 문서에 이벤트 리스너 추가
    document.addEventListener('click', handleElementClick);

    // 클린업 함수에서 이벤트 리스너 제거
    return () => {
      document.removeEventListener('click', handleElementClick);
    };
  }, [htmlFileString]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      style={{
        width: "330px",
        // width: windowSize.width + "px",
        // height: windowSize.height + "px",
        background: "#FFFFFF",
        overflow:'hidden'
        // margin: "50px 20px 50px 20px",
      }}
      trigger={<a href="#none" style={modalLinkStyle}>{content}</a>}
    >
      <div style={{width: "100%"}}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          padding: "12px 12px 12px 16px"
        }}>
          <span style={modalTitleStyle}>개인정보처리방침</span>
          <Image style={{
            width: "24px",
            height: "24px",
            padding: "6px",
            marginTop: "12px,",
            marginRight: "6px",
          }} src='./images/icon/icon_close.svg' onClick={() => setOpen(false)}/>
        </div>
        <div style={{marginLeft: 12, marginBottom: 10}} className='ui form mini'>
          <Dropdown defaultValue={htmlFilePath} options={options} selection onChange={(e, data) => {
            setHtmlFilePath(data.value);
          }}/>
        </div>
      </div>
      <div style={{width: "100%", height: "497px", padding:'0 !important', overflowY: 'auto'}}>
        <div dangerouslySetInnerHTML={{__html: htmlFileString}}/>
      </div>
    </Modal>
  );
}

export default PersonalInformModal;
