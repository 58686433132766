import React from "react";
import {Card, Header, Image, Modal} from "semantic-ui-react";

const PersonalInformModalMo = function ({content}) {
    const [open, setOpen] = React.useState(false);

    const modalCloseIconStyle = {
        display: "block",
        marginLeft: "auto",
        width: "24px",
        height: "24px",
        padding: "6px",
    }
    const modalStyle = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        color: "#FA4614",
        textDecorationLine: "underline",
        cursor: "pointer",

        marginLeft: "4px"
    }
    const modalTitleStyle = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "#000000"
    }
    const firstDivTitleStyle = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
    }
    const firstDivContentStyle = {
        marginTop: "8px",
        textAlign: "left",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px"
    }
    const secondDivContentStyle = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
    }
    const modalTableTrStyle = {
        textAlign: "center"
    }
    const modalTableThStyle1 = {
        width: '35px',
        border: "1px solid #EAECF2",
        borderRadius: "8px 0px 0px 0px",
        background: "#F5F5F5",
        padding: "5px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableThStyle2 = {
        width: '52px',
        border: "1px solid #EAECF2",
        // borderRadius: "8px 0px 0px 0px",
        background: "#F5F5F5",
        padding: "5px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableThStyle3 = {
        border: "1px solid #EAECF2",
        // borderRadius: "8px 0px 0px 0px",
        background: "#F5F5F5",
        padding: "5px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableThStyle4 = {
        width: '65px',
        border: "1px solid #EAECF2",
        borderRadius: "0px 8px 0px 0px",
        background: "#F5F5F5",
        padding: "5px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableThStyle5 = {
        width: "10%",
        border: "1px solid #EAECF2",
        borderRadius: "8px 0px 0px 0px",
        background: "#F5F5F5",
        padding: "12px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableThStyle6 = {
        width: "26%",
        border: "1px solid #EAECF2",
        borderRadius: "0px 8px 0px 0px",
        background: "#F5F5F5",
        padding: "12px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "11px",
        lineHeight: "24px",
        letterSpacing: "-0.4px",
        color: "rgba(0, 0, 0, 0.8)"
    }
    const modalTableTdStyle = {
        border: "1px solid #EAECF2",
        boxSizing: "border-box",
        borderRadius: "0px 0px 8px 0px",
        padding: "11px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
        // textAlign: "left"
        textAlign: "center",
        verticalAlign: "top"
    }
    const modalTableTdBottomLeftStyle = {
        border: "1px solid #EAECF2",
        borderRadius: "0px 0px 0px 8px",
        boxSizing: "border-box",
        padding: "5px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "center",
        verticalAlign: "middle"
    }
    const modalTableTdBottomRightStyle = {
        border: "1px solid #EAECF2",
        borderRadius: "0px 0px 8px 0px",
        boxSizing: "border-box",
        padding: "5px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "left",
        verticalAlign: "top"
    }
    const modalTableTdStyle1_1 = {
        border: "1px solid #EAECF2",
        // borderRadius: "0px 0px 0px 8px",
        boxSizing: "border-box",
        padding: "5px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "center",
        verticalAlign: 'middle'
    }
    const modalTableTdStyle1_2 = {
        border: "1px solid #EAECF2",
        // borderRadius: "0px 0px 0px 8px",
        boxSizing: "border-box",
        padding: "5px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "left",
        verticalAlign: "top"
    }
    const modalTableTdBoldStyle1_2 = {
        border: "1px solid #EAECF2",
        // borderRadius: "0px 0px 0px 8px",
        boxSizing: "border-box",
        padding: "5px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "left",
        verticalAlign: "top"
    }
    const modalTableTdStyle2 = {
        border: "1px solid #EAECF2",
        // borderRadius: "0px 0px 0px 8px",
        boxSizing: "border-box",
        padding: "5px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "center",
        verticalAlign: "middle"
    }
    const modalTableTdBottomLeftStyle2 = {
        border: "1px solid #EAECF2",
        borderRadius: "0px 0px 0px 8px",
        boxSizing: "border-box",
        padding: "5px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "center",
        verticalAlign: "middle"
    }
    const modalTableTdBottomRightStyle2 = {
        border: "1px solid #EAECF2",
        borderRadius: "0px 0px 8px 0px",
        boxSizing: "border-box",
        padding: "5px",

        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
        textAlign: "center",
        verticalAlign: "middle"
    }

    const labelStyle1 = {
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
    }
    const labelStyle2 = {
        marginTop: "8px",
        fontFamily: "Noto Sans KR",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        lineHeight: "18px",
        color: "#000000",
        letterSpacing: "-0.4px",
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            style={{
                width: "320px",
                height: "497px",
                // height: windowSize.height + "px",
                // margin: "50px 20px 50px 20px",
            }}
            trigger={<a href="#none" style={modalStyle}>{content}</a>}
        >
            <Modal.Content style={{
                width: "100%",
                height: "48px",
            }}>
                <Header>
                    <div style={{textAlign: "left", float: "left"}}>
                        <span style={modalTitleStyle}>개인정보 수집·이용 동의서</span>
                    </div>
                    <div>
                        <Image src='./images/icon/icon_close.svg' onClick={() => setOpen(false)}
                               style={modalCloseIconStyle}/>
                    </div>
                </Header>
            </Modal.Content>
            <Modal.Content scrolling style={{
                width: "100%",
                height: "497px"
                // maxHeight: (windowSize.height - 48) + "px",
                // height: (windowSize.height - 48) + "px",
            }}>
                <Card centered style={{width: "100%"}}>
                    <div style={{margin: "10px", marginBottom: "15px"}}>
                        <div style={{textAlign: "center"}}>
                            <span style={firstDivTitleStyle}>개인정보 수집·이용 동의서</span>
                            <p style={firstDivContentStyle}>
                                본인은 (주)지에스리테일(이하 ‘회사’라 함)의 기업복지형 플랫폼 서비스(‘밀박스25’)에 대한 온라인 상담을 신청함에 있어 개인정보보호법에 따라 개인정보를 수집·이용하는 것에 동의합니다.
                            </p>
                        </div>
                        <div style={{marginTop: "15px"}}>
                            <p style={secondDivContentStyle}>
                                제1조 수집하는 개인정보 항목 및 이용 목적 등
                            </p>
                            <table style={{
                                border: "1px solid #F5F5F5",
                                borderCollapse:"separate",
                                borderRadius: "8px",
                                borderLeft: "0",
                                borderSpacing: "0px",
                                width: "100%",
                            }}>
                                <tbody>
                                <tr style={modalTableTrStyle}>
                                    <th style={modalTableThStyle1}>구분</th>
                                    <th style={modalTableThStyle2}>수집항목</th>
                                    <th style={modalTableThStyle3}>목적</th>
                                    <th style={modalTableThStyle4}>보유 및 이용기간</th>
                                </tr>
                                <tr>
                                    <td style={modalTableTdStyle1_1}>필수 정보</td>
                                    <td style={modalTableTdStyle1_2}>
                                        휴대전화번호, 이메일 주소, 배송주소
                                    </td>
                                    <td style={modalTableTdStyle1_2}>
                                        - 온라인 상담 신청 <br/>
                                        - 서비스 소개, 서비스 문의 등 고객 문의에 대한 전화상담 <br/>
                                        - 배송을 위한 커뮤니케이션(전화, 문자, 이메일 및 카카오톡 등을 활용) <br/>
                                        - 공지, 안내를 위한 의사소통 전달 <br/>
                                        - 추천인 포상/혜택 제공
                                    </td>
                                    <td style={modalTableTdBoldStyle1_2}>
                                        ① 온라인 상담 접수 후 3개월
                                        <div>②서비스이용종료일로부터 3개월</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={modalTableTdBottomLeftStyle}>기타</td>
                                    <td style={modalTableTdBottomRightStyle} colSpan={3}>
                                        <div>※ 관계 법령에서 정한 기간동안 보관하는 회원 정보는 아래와 같습니다. </div>
                                        <div>가. 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률) </div>
                                        <div>나. 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률) </div>
                                        <div>다. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률) </div>
                                        <div>라. 표시/광고에 관한 기록 : 6개월 </div>
                                        <div>마. 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류 : 5년 </div>
                                        <div>바. 전자금융에 관한 기록 : 5년 </div>
                                        <div>사. 로그인 기록 : 3개월 </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div style={{marginTop: "8px"}}>
                                <label style={labelStyle1}>
                                    ※ 개인정보 수집 및 이용을 거부할 수 있는 권리가 있으나, 거부 시 서비스 이용이 불가합니다.
                                </label>
                            </div>
                        </div>
                    </div>
                </Card>
            </Modal.Content>
        </Modal>
    );
}

export default PersonalInformModalMo;
